@use '@/styles/utils/mixins.scss' as *;

.ctaBannerWrapper {
  margin-top: 3.75rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6.75rem;
  @include tab() {
    margin-top: 5.125rem;
  }

  .ctaBannerContent {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 3.75rem;
    align-items: center;
    width: 100%;
    text-align: center;
  }
  .ctaBannerTitle {
    display: flex;
    flex-direction: column;
    span {
      color: $dark;
    }
    @include tab() {
      text-align: center;
      align-items: center;
    }
  }

  .description {
    width: 100%;
    max-width: 50rem;
    p {
      &:not(:last-child) {
        margin-bottom: 1rem;
      }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-weight: bold;
      font-size: 1.5rem;
      @include tab() {
        font-size: 1.25rem;
      }
      &:not(:last-child) {
        margin-bottom: 1rem;
      }
      &:not(:first-child) {
        margin-top: 1rem;
      }
    }

    p {
      margin-bottom: 1rem;
    }
    ul,
    ol {
      padding-left: 1.5rem;
      margin-bottom: 1rem;
    }
    a {
      color: $primary;
      &:hover,
      &:focus,
      &:active {
        color: $primaryAlt;
      }
    }
  }
  .bannerImageContainer {
    position: relative;
    overflow: hidden;
    border-radius: 1.25rem;
    width: 100%;
    height: 33.75rem;

    @include mob() {
      height: 29.25rem;
    }
  }

  .bannerImageWrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    transition: all 0.1s ease;
  }

  .bannerImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
